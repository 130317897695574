.zeroPadding {
    padding: 0 !important;
    padding-bottom: var(--spacing-size-five);
}

.tabs {
    display: flex;
    flex: 1 0 100%;
    border-bottom: var(--color-brand-aluminium) 1px solid;
}

.tab {
    display: flex;
    padding: var(--spacing-size-five) var(--spacing-size-three) var(--spacing-size-five) 0;
    color: var(--text-secondary);
    cursor: pointer;
}

.tab.active {
    color: var(--text-primary);
    border-bottom: var(--color-black) 1px solid;
}
