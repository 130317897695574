.root {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 991px) {
    .root.compact {
        width: initial;
    }
}

.column {
    flex: 1;
}
